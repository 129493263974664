import React from "react";
import Alerts from "../components/Alerts";
import { alertsPageSeoTags } from "../helpers/seoTags";

const AlertsPage = () => {
  return (
    <>
      {alertsPageSeoTags()}

      <Alerts />
    </>
  );
};

export default AlertsPage;
