import { useEffect, useMemo, useRef, useState } from "react"
import DatePicker from "react-datepicker"
import { Box, Flex, Text } from "@chakra-ui/react"
import "react-datepicker/dist/react-datepicker.css"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import pickBy from "lodash/pickBy"
import { addMonths } from "date-fns"

import { Select } from "../Select"
import { getFlightDates, getPricingPlans } from "../../services/api"

import { ReactComponent as ReverseFlightsIcon } from "../../img/reverse_flights_icon.svg"
import FlightLoadingProgress from "./components/FlightLoadingProgress.js"

import {
  CustomCalendarContainer,
  DatePickerInput,
  RenderCustomHeader,
  RenderDayContents,
} from "./components/DatePickerProps.js"
import { ANY, CITY } from "../../constants/constants.js"
import EnhancedFiltration from "./components/EnhancedFiltration.js"

const oneWayOptions = [
  { value: 1, label: "One way" },
  { value: 2, label: "Round trip" },
]

// const passengerCountOptions = [
//   { value: 1, label: "1" },
//   { value: 2, label: "2" },
// ]

const FilterPanel = ({
  isOneWay,
  from,
  to,
  start_date,
  end_date,
  program,
  stops,
  is_reversed,
  passengerCount,
  dateModified,
  onChange,
  originAirportsQuery,
  destinationAirportsQuery,
  isFreePlan,
  finishedFlightUpdate,
  user,
  handleReverseFlights,
  isFetchingUpdatedFlightData,
  flightsData,
}) => {
  const wrapperRef = useRef(null)
  const prevFinishedFlightUpdate = useRef(finishedFlightUpdate)

  const [isOpen, setIsOpen] = useState(false)
  const [showFlightLoadingProgress, setFlightLoadingProgress] = useState(
    !!flightsData?.results
  )

  const queryClient = useQueryClient()

  useEffect(() => {
    if (!!flightsData?.results && !finishedFlightUpdate) {
      setFlightLoadingProgress(true)
    } else {
      const timer = setTimeout(() => setFlightLoadingProgress(false), 1000)
      return () => clearTimeout(timer)
    }
  }, [flightsData?.results, finishedFlightUpdate])

  // Close DatePicker on outside click
  useOutsideAlerter(wrapperRef, () => setIsOpen(false))

  function useOutsideAlerter(ref, onOutsideClick) {
    useEffect(() => {
      // Function to call on click outside of element
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          onOutsideClick()
        }
      }

      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside)
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside)
      }
    }, [ref, onOutsideClick])
  }

  const handleClosePicker = () => {
    setIsOpen(false)
  }
  const handleTogglePicker = () => setIsOpen(!isOpen)

  const handleDateChange = (dates) => {
    if (Array.isArray(dates)) {
      const [start, end] = dates

      onChange({ start_date: start, end_date: end, is_reversed: false })

      if (start && end) {
        setIsOpen(false)
      }
    } else {
      onChange({ start_date: dates, is_reversed: false })
      setIsOpen(false)
    }
  }

  const flightDatesParams = pickBy({
    origin: from?.type === CITY ? from?.name : from?.value,
    destination: to?.type === CITY ? to?.name : to?.value,
  })
  const reversedFlightDatesParams = pickBy({
    origin: to?.type === CITY ? to?.name : to?.value,
    destination: from?.type === CITY ? from?.name : from?.value,
  })

  const shouldReverseQuery = !isOneWay && !!start_date && !end_date

  const queryKey = useMemo(
    () => [
      shouldReverseQuery ? "reversedFlightDates" : "flightDates",
      shouldReverseQuery ? reversedFlightDatesParams : flightDatesParams,
    ],
    [shouldReverseQuery, reversedFlightDatesParams, flightDatesParams]
  )

  const flightDatesQuery = useQuery({
    queryKey: queryKey,
    queryFn: getFlightDates,
    initialData: [],
    enabled: !!(from && to),
  })
  const reversedFlightDatesQuery = useQuery({
    queryKey: ["reversedFlightDates", reversedFlightDatesParams],
    queryFn: getFlightDates,
    initialData: [],
    enabled: !!(from && to),
  })
  const { data: pricingPlans } = useQuery({
    queryKey: ["pricingPlans"],
    queryFn: getPricingPlans,
    initialData: [],
  })

  const today = new Date()
  const twoMonthsLater = addMonths(today, 2)
  const oneYearLater = addMonths(today, 12)

  const sortedOriginAirports = originAirportsQuery.data.sort((a, b) =>
    a.name.trim().localeCompare(b.name.trim())
  )

  const sortedDestinationAirports = destinationAirportsQuery.data.sort((a, b) =>
    a.name.trim().localeCompare(b.name.trim())
  )

  const originAirportOptions = sortedOriginAirports.map((airport) => {
    return {
      value: airport.code,
      label: `${airport.city} (${airport.type === CITY ? ANY : airport.code})`,
      fullLabel:
        airport.type === CITY
          ? airport.name.replace("(All)", `(${airport.code})`)
          : `${airport.name} (${airport.code})`,
      city: airport.city,
      type: airport.type,
      name: airport.name,
      searchBy: `${airport.name} (${airport.code}) ${airport.city}`,
    }
  })

  const destinationAirportOptions = sortedDestinationAirports.map((airport) => {
    return {
      value: airport.code,
      label: `${airport.city} (${airport.type === CITY ? ANY : airport.code})`,
      fullLabel:
        airport.type === CITY
          ? airport.name.replace("(All)", `(${airport.code})`)
          : `${airport.name} (${airport.code})`,
      city: airport.city,
      type: airport.type,
      name: airport.name,
      searchBy: `$(${airport.code}) ${airport.city}`,
    }
  })

  const allowedDates = flightDatesQuery.data.map(({ date }) => {
    return new Date(date)
  })

  if (allowedDates.sort().at(-1) < twoMonthsLater && isFreePlan) {
    allowedDates.push(addMonths(today, 3))
  }

  const handleIsOneWaySelectChange = (option) => {
    onChange({
      isOneWay: option.value === 1,
      start_date: new Date(),
      end_date: new Date(),
      is_reversed: false,
    })
  }

  // const handlePassengerCountSelectChange = (option) => {
  //   // onChange({
  //   //   passengerCount: option.value,
  //   // })
  // }

  // const labelLeftText = useMemo(
  //   () => (
  //     <Image
  //       mr={1}
  //       src={userCircle}
  //       fill={"red"}
  //       alt={"select_number_of_passengers"}
  //     />
  //   ),
  //   []
  // )

  useEffect(() => {
    if (finishedFlightUpdate && prevFinishedFlightUpdate.current === false) {
      queryClient.invalidateQueries(queryKey)
    }
    prevFinishedFlightUpdate.current = finishedFlightUpdate
  }, [finishedFlightUpdate, queryClient, queryKey])

  return (
    <Flex
      p={4}
      bg={"white"}
      borderRadius={{ base: 0, lg: 12 }}
      boxShadow="0px 2px 8px 0px rgba(20, 23, 37, 0.08)"
    >
      <Flex maxWidth={"1086px"} width={"100%"} flexDirection={"column"} gap={4}>
        <Flex flexDirection={"column"} gap={2}>
          <Flex justifyContent={"space-between"} alignItems={"center"}>
            <Flex gap={2}>
              <Box bg={"#fff"} rounded={"12px"} width={"104px"}>
                <Select
                  onChange={handleIsOneWaySelectChange}
                  value={!isOneWay ? oneWayOptions[1] : oneWayOptions[0]}
                  options={oneWayOptions}
                  isDisabled={reversedFlightDatesQuery.data.length === 0}
                  singleValueStyles={{
                    fontSize: "14px",
                    paddingInlineStart: "0.5rem",
                    leftText: "none",
                  }}
                />
              </Box>

              {/* <Box bg={COLORS.grey} rounded={"12px"} width={"90px"}>
                <Select
                  placeholder="1"
                  labelLeftText={labelLeftText}
                  onChange={handlePassengerCountSelectChange}
                  value={passengerCountOptions[passengerCount - 1]}
                  options={passengerCountOptions}
                  singleValueStyles={{
                    fontSize: "14px",
                    paddingInlineStart: "0.5rem",
                    leftText: "none",
                  }}
                  isDisabled={true}
                />
              </Box> */}
            </Flex>

            <Text fontSize={{ base: "14px", md: "16px" }}>
              * indicates mixed cabin
            </Text>
          </Flex>
          <Flex
            justifyContent="space-between"
            direction={{ base: "column", lg: "row" }}
            gap={3}
          >
            <Flex
              gap={1}
              display={"flex"}
              direction={{ base: "column", lg: "row" }}
            >
              <Box bg={"#fff"} rounded={"12px"} w={{ xl: 220 }}>
                <Select
                  placeholder="Where from?"
                  labelLeftText={"From:"}
                  onChange={(value) => {
                    onChange({ from: value, is_reversed: false })
                  }}
                  value={from}
                  options={originAirportOptions}
                  isSearchable={true}
                  focused={true}
                  showArrow={false}
                />
              </Box>

              <Flex
                sx={{
                  width: { base: "100%", lg: "40px" },
                  height: "40px",
                  alignItems: "center",
                  justifyContent: "center",
                  border: "1px solid #F2F4F7",
                  borderRadius: "12px",
                  cursor: from && to ? "pointer" : "not-allowed",
                  bg: "white",
                }}
                onClick={from && to ? handleReverseFlights : null}
              >
                <ReverseFlightsIcon />
              </Flex>

              <Box bg={"#fff"} rounded={"12px"} w={{ xl: 220 }}>
                <Select
                  key={to?.label}
                  placeholder="Where to?"
                  labelLeftText={"To:"}
                  onChange={(value) =>
                    onChange({ to: value, is_reversed: false })
                  }
                  value={to}
                  options={destinationAirportOptions}
                  isSearchable={true}
                  isDisabled={!from}
                />
              </Box>
            </Flex>

            <Flex gap={3} marginInlineStart={0} width={"100%"} ref={wrapperRef}>
              <Flex
                bg={"#fff"}
                rounded={"12px"}
                justify="center"
                align="center"
                w={"100%"}
                sx={{
                  "& > div:first-of-type": {
                    zIndex: 10,
                  },
                  "& > div:last-of-type": {
                    zIndex: 12,
                  },
                  "@media (max-width: 767px)": {
                    "& .react-datepicker-popper": {
                      inset: "auto!important",
                      transform: "none!important",
                      position: "fixed!important",
                      left: "0!important",
                      bottom: "0!important",
                      width: "100%",
                      overflowY: "auto",
                      display: "flex",
                      alignItems: "flex-end",
                      height: "100vh",
                      backgroundColor: "rgba(0, 0, 0, 0.6)",
                      paddingTop: "0px!important",
                      "> div": {
                        overflowY: "auto",
                        width: "100%",
                        maxHeight: "100%",
                      },
                    },
                  },
                }}
              >
                <DatePicker
                  key={`picker-${start_date}-${end_date}`}
                  open={isOpen}
                  onChange={handleDateChange}
                  disabled={!to}
                  selectsRange={!isOneWay}
                  selected={start_date}
                  startDate={start_date}
                  endDate={end_date}
                  monthsShown={!isOneWay ? 2 : 1}
                  customInput={
                    <DatePickerInput
                      handleTogglePicker={() => {
                        if (to) {
                          handleTogglePicker()
                        }
                      }}
                    />
                  }
                  dateFormat="EEE, dd MMMM"
                  includeDates={null}
                  minDate={today}
                  maxDate={isFreePlan ? twoMonthsLater : oneYearLater}
                  renderDayContents={(day, date, otherProps) => (
                    <RenderDayContents
                      flightDatesQuery={flightDatesQuery}
                      day={day}
                      date={date}
                      {...otherProps}
                    />
                  )}
                  renderCustomHeader={(props) => (
                    <RenderCustomHeader
                      isOneWay={isOneWay}
                      twoMonthsLater={twoMonthsLater}
                      oneYearLater={oneYearLater}
                      isFreePlan={isFreePlan}
                      pricingPlans={pricingPlans}
                      user={user}
                      {...props}
                    />
                  )}
                  calendarContainer={(props) => {
                    return (
                      <CustomCalendarContainer
                        handleClosePicker={handleClosePicker}
                        {...props}
                      />
                    )
                  }}
                />
              </Flex>
            </Flex>
          </Flex>
        </Flex>

        <Flex flexDirection={"column"} gap={4}>
          <EnhancedFiltration
            from={from}
            to={to}
            isOneWay={isOneWay}
            program={program}
            stops={stops}
            is_reversed={is_reversed}
            onChange={onChange}
            isFreePlan={isFreePlan}
            isSuccessFlights={!!flightsData?.results}
          />

          {!isFreePlan && showFlightLoadingProgress && dateModified && (
            <FlightLoadingProgress
              isFetchingUpdatedFlightData={isFetchingUpdatedFlightData}
              finishedFlightUpdate={finishedFlightUpdate}
              fromCode={from?.value || ""}
              toCode={to?.value || ""}
            />
          )}
        </Flex>
      </Flex>
    </Flex>
  )
}

export default FilterPanel
