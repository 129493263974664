import {
  IconButton,
  Box,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverCloseButton,
  PopoverHeader,
  PopoverBody,
  Button,
  useDisclosure,
  Image,
  Textarea,
  Text,
} from "@chakra-ui/react"
import { COLORS } from "../../../constants/constants"
import helpIcon from "../../../img/noun-plane-trip.svg"
import closeIcon from "../../../img/window-close.svg"
import { useAuthContext } from "../../../services/auth"
import { useState } from "react"
import { userProposal } from "../../../services/api"
import { useQuery, useQueryClient } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"

const HelpPopover = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const { user } = useAuthContext()
  const { onOpen, onClose, isOpen } = useDisclosure()
  const [preferredAirport, setPreferredAirport] = useState("")
  const [errorText, setErrorText] = useState("")

  const params = {
    userId: user?.id,
    request_text: preferredAirport,
  }

  const { data, refetch, isFetching, isError } = useQuery({
    queryKey: ["userProposal", params],
    queryFn: userProposal,
    enabled: false,
  })

  const handleSubmitPopover = () => {
    if (preferredAirport.trim()) {
      setErrorText("")
      refetch()
    } else {
      setErrorText("Please enter preferred airport")
    }
  }

  const handleClosePopover = (e) => {
    setErrorText("")
    setPreferredAirport("")
    queryClient.removeQueries(["userProposal"])
    onClose(e)
  }

  return (
    <Box
      textAlign={"left"}
      position="fixed"
      bottom="20px"
      right="20px"
      zIndex={12}
    >
      <Popover
        isOpen={isOpen}
        onOpen={onOpen}
        onClose={handleClosePopover}
        placement="top-end"
        closeOnBlur={false}
      >
        <PopoverTrigger>
          {isOpen ? (
            <IconButton
              icon={<Image src={closeIcon} />}
              borderRadius="full"
              aria-label="Help"
              w={"64px"}
              h={"64px"}
              bg={COLORS.red}
            />
          ) : (
            <IconButton
              icon={<Image src={helpIcon} />}
              borderRadius="full"
              aria-label="Help"
              w={"64px"}
              h={"64px"}
              bg={COLORS.red}
            />
          )}
        </PopoverTrigger>

        <PopoverContent width={"360px"} borderRadius="12px">
          <PopoverArrow />
          <PopoverCloseButton size={"md"} top={"26px"} right={"12px"} />
          <PopoverHeader
            fontSize={"24px"}
            fontWeight={700}
            p={"24px 24px 0"}
            mb={4}
            border={"none"}
          >
            {t("help.title")}
          </PopoverHeader>
          <PopoverBody p={"0 24px 24px"}>
            <Box mb={4} fontSize={"14px"}>
              {t("help.description")}
            </Box>

            <Textarea
              placeholder={t("help.placeholder")}
              height={"112px"}
              maxHeight={"500px"}
              onChange={(e) => setPreferredAirport(e.target.value)}
            />
            {data?.message && (
              <Text fontSize={"14px"} color={COLORS.green} mt={1}>
                {t("help.successMessage")}
              </Text>
            )}
            {errorText && (
              <Text fontSize={"14px"} color={COLORS.red} mt={1}>
                {errorText}
              </Text>
            )}

            {isError && (
              <Text fontSize={"14px"} color={COLORS.red} mt={1}>
                {t("help.errorText")}
              </Text>
            )}
            <Button
              bg={COLORS.red}
              color={COLORS.white}
              w="full"
              onClick={handleSubmitPopover}
              isLoading={isFetching}
              fontSize={"14px"}
              mt={6}
            >
              {t("help.submit")}
            </Button>
          </PopoverBody>
        </PopoverContent>
      </Popover>
    </Box>
  )
}

export default HelpPopover
