import React, { Fragment } from "react"
import { useTranslation } from "react-i18next"
import { format, differenceInCalendarDays, parseISO, addDays } from "date-fns"

import { trackPage } from "../../../services/analytics"

import cardImage from "../../../img/card.svg"
import bellImage from "../../../img/bell.svg"

import {
  Text,
  Tr,
  Td,
  Image,
  Show,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverBody,
  PopoverCloseButton,
  Box,
  useBreakpointValue,
} from "@chakra-ui/react"

import { COLORS, flightClassesMapping } from "../../../constants/constants"

import ExpandableRow from "./ExpandableRow"
import flightImages from "../../../flightImages"
import {
  formatTime,
  getSeatText,
  renderBookingDetails,
} from "../../../helpers/functions"
import AlertRouteContent from "../../AlertRouteContent"

const numberFormat = new Intl.NumberFormat()

const formatTaxValue = (taxValue) => {
  const roundedValue = Math.round(taxValue)

  if (roundedValue >= 10000) {
    return roundedValue.toLocaleString()
  } else {
    return roundedValue.toString()
  }
}

const FlightRow = ({ flight, expandedFlight, setExpandedFlight, user }) => {
  const { t } = useTranslation()
  const placement = useBreakpointValue({ base: "bottom", sm: "left" })

  const canCreateAlerts = user?.remaining_alerts || 0

  const { details } = flight

  let firstAirlineImageAlt = ""
  let secondAirlineImageAlt = ""

  const summaryPoints = flight.class_details.reduce(
    (acc, item) => ({
      ...acc,
      [item.designated_class]: {
        points: item.points_per_adult,
        name: t(`table.${flightClassesMapping[item.designated_class]}`),
        tax_per_adult: formatTaxValue(item.tax_per_adult),
        tax_currency: item.tax_currency,
        remaining_seats: item.remaining_seats,
        mixed_cabin: item.mixed_cabin,
      },
    }),
    {}
  )

  const highestPoint =
    summaryPoints["First"] ||
    summaryPoints["Business"] ||
    summaryPoints["PremiumEconomy"] ||
    summaryPoints["Economy"]

  // set How to Book? content based on airline source
  const {
    airlineAwardImage,
    bookingContentComponent,
    airlineAwardImageAlt,
    preferredPrograms,
  } = renderBookingDetails(flight, highestPoint, t)

  if (details[0]?.aircraft_details.startsWith("EK")) {
    firstAirlineImageAlt = `book_emirates_reward_flights_to_${flight.destination.name}`
  } else if (details[0]?.aircraft_details.startsWith("UA")) {
    firstAirlineImageAlt = `book_united_airlines_reward_flights_to_${flight.destination.name}`
  } else {
    firstAirlineImageAlt = `book_${flight.origin.name}_reward_flights_to_${flight.destination.name}`
  }

  if (details.length === 2 && details[1]?.aircraft_details.startsWith("EK")) {
    secondAirlineImageAlt = `book_emirates_reward_flights_to_${flight.destination.name}`
  } else if (details[1]?.aircraft_details.startsWith("UA")) {
    secondAirlineImageAlt = `book_united_airlines_reward_flights_to_${flight.destination.name}`
  } else {
    secondAirlineImageAlt = `book_${flight.origin.name}_reward_flights_to_${flight.destination.name}`
  }

  const planeImage =
    details.length >= 3
      ? flightImages("group_3_plus")
      : flightImages(details[0].aircraft_details.slice(0, 2))

  const secondPlaneImage =
    details.length === 2 && !!planeImage
      ? flightImages(details[1]?.aircraft_details.slice(0, 2))
      : null

  const departureDate = new Date(details[0].departure_date)
  const arrivalDate = new Date(details[details.length - 1].arrival_date)
  const diffInDays = differenceInCalendarDays(arrivalDate, departureDate)

  const route = {
    origin: flight.origin,
    destination: flight.destination,
    startDate: format(parseISO(flight.flight_start_date), "yyyy-MM-dd"),
    endDate: format(
      addDays(parseISO(flight.flight_start_date), 1),
      "yyyy-MM-dd"
    ),
    flightClasses: Object.keys(summaryPoints),
    preferredPrograms: [preferredPrograms],
    source: [flight.source],
    isNew: true,
  }

  const isFlightExpanded = expandedFlight === flight

  const checkIfSameAirline = (details) => {
    const regex = /\((.*?)\)/

    // Extract the company names from each string in the array
    const companyNames = details.map((detail) => {
      const match = detail.aircraft_details.match(regex)

      return match ? match[1] : null
    })

    // Check if all extracted company names are the same
    return companyNames.every((name, _, arr) => name === arr[0])
  }

  return (
    <Fragment key={flight.id}>
      <Tr
        fontSize={[12, 14]}
        position={isFlightExpanded ? "relative" : "initial"}
        zIndex={12}
        backgroundColor={isFlightExpanded ? COLORS.grey : COLORS.white}
        transform={isFlightExpanded ? "translateZ(1px)" : "none"}
        boxShadow={
          isFlightExpanded
            ? "0px -10px 18px 0px rgba(20, 23, 37, 0.13)"
            : "none"
        }
        fontWeight="semibold"
        onClick={() => {
          setExpandedFlight(expandedFlight === flight ? null : flight)
        }}
        cursor="pointer"
        sx={{ "& td": { borderBottom: "1px solid #E6E6E6" } }}
      >
        <Show above="lg">
          <Td
            p={"8px"}
            width={"50px"}
            position={"relative"}
            border={isFlightExpanded ? "none" : ""}
          >
            <Image
              width="100%"
              src={planeImage || flightImages("group_3_plus")}
              alt={firstAirlineImageAlt}
              margin={"0 auto"}
              position={"relative"}
              right="-4px"
              zIndex={1}
              top={secondPlaneImage ? "5px" : "0px"}
              maxW={"32px"}
            />
            {secondPlaneImage && !checkIfSameAirline(details) && (
              <Image
                width={"100%"}
                src={secondPlaneImage}
                alt={secondAirlineImageAlt}
                margin={"0 auto"}
                position={"relative"}
                right={"4px"}
                bottom={"18px"}
                zIndex={0}
                maxW={"32px"}
              />
            )}
          </Td>
        </Show>
        <Td p={2} border={isFlightExpanded ? "none" : ""}>
          <Text>
            {formatTime(flight.flight_start_date)} -{" "}
            {formatTime(flight.flight_end_date)}
            {diffInDays > 0 && <Text as="sup"> +{diffInDays}</Text>}
          </Text>
          <Text fontSize={12}>
            {details
              .map((conn) => conn.aircraft_details.split("(")[0])
              .join(", ")}
          </Text>
        </Td>
        <Show below="lg">
          <Td p={2} border={isFlightExpanded ? "none" : ""} fontSize={12}>
            {highestPoint ? (
              <Box position={"relative"}>
                {highestPoint.mixed_cabin && (
                  <Text
                    position={"absolute"}
                    left={"-12px"}
                    top={"-4px"}
                    fontSize={"14px"}
                  >
                    *
                  </Text>
                )}
                <Text color={COLORS.red}>
                  {highestPoint.points}
                  <Text as={"span"} fontSize={10}>
                    {" "}
                    +{highestPoint.tax_currency}
                    {highestPoint.tax_per_adult}
                  </Text>
                </Text>
                <Text color={COLORS.black}>{highestPoint.name}</Text>
                <Text color={COLORS.black}>
                  {getSeatText({
                    remainingSeats: highestPoint.remaining_seats,
                    source: flight.source,
                  })}
                </Text>
              </Box>
            ) : (
              "-"
            )}
          </Td>
        </Show>
        <Show above="lg">
          <Td p={2} border={isFlightExpanded ? "none" : ""} fontSize={"12px"}>
            <Text>{details.length === 1 ? "Direct" : details.length - 1}</Text>
            <Text fontSize={12} color={"#6A6E85"}>
              {details
                .slice(0, -1)
                .map((conn) => conn.to_airport.match(/\(([^)]+)\)$/)[1])
                .join(", ")}
            </Text>
          </Td>
          <Td p={2} border={isFlightExpanded ? "none" : ""}>
            {summaryPoints["Economy"] ? (
              <Box position={"relative"}>
                {summaryPoints["Economy"].mixed_cabin && (
                  <Text
                    position={"absolute"}
                    left={"-12px"}
                    top={"-4px"}
                    fontSize={"14px"}
                  >
                    *
                  </Text>
                )}
                <Text color={COLORS.red}>
                  {numberFormat.format(summaryPoints["Economy"].points)}
                  <Text as="span" fontSize={10}>
                    {" "}
                    +{summaryPoints["Economy"].tax_currency}
                    {summaryPoints["Economy"].tax_per_adult}
                  </Text>
                </Text>
                <Text color={COLORS.black} fontSize={"xs"}>
                  {getSeatText({
                    remainingSeats: summaryPoints["Economy"].remaining_seats,
                    source: flight.source,
                  })}
                </Text>
              </Box>
            ) : (
              "-"
            )}
          </Td>
          <Td p={2} border={isFlightExpanded ? "none" : ""}>
            {summaryPoints["PremiumEconomy"] ? (
              <Box position={"relative"}>
                {summaryPoints["PremiumEconomy"].mixed_cabin && (
                  <Text
                    position={"absolute"}
                    left={"-12px"}
                    top={"-4px"}
                    fontSize={"14px"}
                  >
                    *
                  </Text>
                )}
                <Text color={COLORS.red}>
                  {numberFormat.format(summaryPoints["PremiumEconomy"].points)}
                  <Text as="span" fontSize={10} color={COLORS.red}>
                    {" "}
                    +{summaryPoints["PremiumEconomy"].tax_currency}
                    {summaryPoints["PremiumEconomy"].tax_per_adult}
                  </Text>
                </Text>
                <Text color={COLORS.black} fontSize="xs">
                  {getSeatText({
                    remainingSeats:
                      summaryPoints["PremiumEconomy"].remaining_seats,
                    source: flight.source,
                  })}
                </Text>
              </Box>
            ) : (
              "-"
            )}
          </Td>
          <Td p={2} border={isFlightExpanded ? "none" : ""}>
            {summaryPoints["Business"] ? (
              <Box position={"relative"}>
                {summaryPoints["Business"].mixed_cabin && (
                  <Text
                    position={"absolute"}
                    left={"-12px"}
                    top={"-4px"}
                    fontSize={"14px"}
                  >
                    *
                  </Text>
                )}
                <Text color={COLORS.red}>
                  {numberFormat.format(summaryPoints["Business"].points)}
                  <Text as={"span"} fontSize={10}>
                    {" "}
                    +{summaryPoints["Business"].tax_currency}
                    {summaryPoints["Business"].tax_per_adult}
                  </Text>
                </Text>
                <Text color={COLORS.black} fontSize={"xs"}>
                  {getSeatText({
                    remainingSeats: summaryPoints["Business"].remaining_seats,
                    source: flight.source,
                  })}
                </Text>
              </Box>
            ) : (
              "-"
            )}
          </Td>
          <Td p={2} border={isFlightExpanded ? "none" : ""}>
            {summaryPoints["First"] ? (
              <Box position={"relative"}>
                {summaryPoints["First"].mixed_cabin && (
                  <Text
                    position={"absolute"}
                    left={"-12px"}
                    top={"-4px"}
                    fontSize={"14px"}
                  >
                    *
                  </Text>
                )}
                <Text color={COLORS.red}>
                  {numberFormat.format(summaryPoints["First"].points)}
                  <Text as="span" fontSize={10}>
                    {" "}
                    +{summaryPoints["First"].tax_currency}
                    {summaryPoints["First"].tax_per_adult}
                  </Text>
                </Text>
                <Text color={COLORS.black} fontSize="xs">
                  {getSeatText({
                    remainingSeats: summaryPoints["First"].remaining_seats,
                    source: flight.source,
                  })}
                </Text>
              </Box>
            ) : (
              "-"
            )}
          </Td>
        </Show>
        <Td p={2} border={isFlightExpanded ? "none" : ""}>
          <Popover
            placement="left"
            onOpen={() => {
              trackPage({ title: "How to Book? | QFF" })
            }}
          >
            <PopoverTrigger>
              <Image
                src={airlineAwardImage}
                margin="0 auto"
                width="28px"
                height="28px"
                alt={airlineAwardImageAlt}
                onClick={(e) => {
                  e.stopPropagation()
                }}
              />
            </PopoverTrigger>
            <PopoverContent
              w={{ base: 240, sm: 340 }}
              p={6}
              _focus={{ boxShadow: "none" }}
              borderRadius={8}
              onClick={(e) => e.stopPropagation()}
            >
              <PopoverBody p={0}>{bookingContentComponent}</PopoverBody>
            </PopoverContent>
          </Popover>
        </Td>
        <Show above="lg">
          <Td p={2} border={isFlightExpanded ? "none" : ""}>
            {/* We will open this logic, when activate Earn Points */}
            {/* {flight.source === "QF" ? (
                        <Popover
                          placement="left"
                          onOpen={() => {
                            trackPage({
                              title: "Earn Points",
                              destination: flight.destination,
                            })
                          }}
                          isOpen={false}
                        >
                          <PopoverTrigger>
                            <Image src={cardImage} margin="0 auto" />
                          </PopoverTrigger>
                          <PopoverContent
                            p={5}
                            pb={0}
                            _focus={{ boxShadow: "none" }}
                            boxShadow="0px 10px 22px rgba(0, 0, 0, 0.14);"
                            borderRadius={8}
                          >
                            <PopoverBody p={0}>
                              <EarnPointsContent
                                points={highestPoint.points}
                                destinationAirport={flight.destination}
                              />
                            </PopoverBody>
                          </PopoverContent>
                        </Popover>
                      ) : ( */}
            <Image
              src={cardImage}
              margin="0 auto"
              opacity={0.3}
              alt={"how_to_earn_more_reward_points"}
            />
          </Td>
        </Show>
        <Td
          p={2}
          border={isFlightExpanded ? "none" : ""}
          sx={{
            "& > div": {
              minWidth: "unset!important",
            },
          }}
        >
          <Popover
            placement={placement}
            closeOnBlur={true}
            onOpen={() => {
              trackPage({
                title: "Alert Route",
              })
            }}
          >
            {({ onClose }) => (
              <>
                <PopoverTrigger>
                  <Image
                    src={bellImage}
                    alt={`create_reward_flight_alerts_from_${flight.origin.name}_to_${flight.destination.name}`}
                    onClick={(e) => {
                      e.stopPropagation()
                    }}
                    margin="0 auto"
                  />
                </PopoverTrigger>
                <PopoverContent
                  _focus={{ boxShadow: "none" }}
                  boxShadow="0px 10px 22px rgba(0, 0, 0, 0.14);"
                  borderRadius={8}
                  w={{ base: "100%", sm: 380 }}
                  onClick={(e) => e.stopPropagation()}
                >
                  {!!canCreateAlerts && <PopoverCloseButton />}
                  <PopoverBody p={0}>
                    {!!canCreateAlerts ? (
                      <AlertRouteContent route={route} onClose={onClose} />
                    ) : (
                      <Box>
                        <Text>{t("table.maxAlertReached")}</Text>
                      </Box>
                    )}
                  </PopoverBody>
                </PopoverContent>
              </>
            )}
          </Popover>
        </Td>
      </Tr>
      {isFlightExpanded && (
        <Tr
          backgroundColor={COLORS.grey}
          boxShadow="0px 15px 18px 0px rgba(20, 23, 37, 0.13)"
          position={"relative"}
          zIndex={1}
        >
          <Td colSpan={11} p={2} border={0}>
            <ExpandableRow flight={flight} />
          </Td>
        </Tr>
      )}
    </Fragment>
  )
}

export default FlightRow
