import React from "react"
import { Box, Flex } from "@chakra-ui/react"
import { Select } from "../Select"
import { GroupedSelect } from "../GroupedSelect"
import { addMonths, format } from "date-fns"

import "react-datepicker/dist/react-datepicker.css"
import { DATES_OPTIONS, PROGRAMS_OPTIONS } from "../../constants/constants"

const getMonthOptions = () => {
  const currentDate = new Date()
  const currentMonth = format(currentDate, "MMM yy")
  const nextMonth = format(addMonths(currentDate, 1), "MMM yy")

  return DATES_OPTIONS.map((option) => {
    return {
      ...option,
      isDisabled:
        !(option.label === currentMonth || option.label === nextMonth) &&
        !(option.label === "All"),
    }
  })
}

const FilterPanel = ({
  onChange,
  from,
  to,
  program,
  date,
  isFreePlan,
  selectOptions,
}) => {
  const filterHelper = (currentOptions, diffWith) => {
    const $options = []
    currentOptions.forEach((option) => {
      $options.push({
        ...option,
        options: option.options.filter((os) => os.value !== diffWith),
      })
    })
    return $options
  }

  const filteredFromOptions = to?.value
    ? filterHelper(selectOptions, to.value)
    : null
  const filteredToOptions = from?.value
    ? filterHelper(selectOptions, from.value)
    : null

  const findValue = (options, value) => {
    for (let option of options) {
      if (option.value === value) {
        return option
      }
      if (option.options && Array.isArray(option.options)) {
        const subOptionMatch = option.options.find(
          (subOption) => subOption.value === value
        )
        if (subOptionMatch) {
          return subOptionMatch
        }
      }
    }
    return null
  }

  const fromValue =
    filteredFromOptions && from?.value
      ? findValue(filteredFromOptions, from.value)
      : null
  const toValue =
    filteredToOptions && to?.value
      ? findValue(filteredToOptions, to.value)
      : null

  return (
    <Flex
      px={4}
      pt={{ base: 0, lg: 4 }}
      pb={{ base: 4 }}
      justifyContent={"space-between"}
      alignItems={{ base: "flex-start", xl: "center" }}
      flexDirection={{ base: "column", xl: "row" }}
      gap={{ base: 4, xl: 8 }}
    >
      <Flex
        flexDirection={{ base: "column", lg: "row" }}
        gap={{ base: 4, lg: 8 }}
        width={{ base: "100%" }}
      >
        <Box
          flex={1}
          bg={"#FFFFFF"}
          borderRadius={"12px"}
          maxW={{ base: "100%", lg: 218 }}
        >
          <GroupedSelect
            placeholder="From"
            labelLeftText={"From:"}
            onChange={(value) => onChange({ from: value })}
            value={fromValue}
            options={filteredFromOptions}
            isSearchable={true}
            isFreePlan={isFreePlan}
          />
        </Box>

        <Box
          flex={1}
          bg={"#FFFFFF"}
          borderRadius={"12px"}
          maxW={{ base: "100%", lg: 218 }}
        >
          <GroupedSelect
            placeholder="To"
            labelLeftText={"To:"}
            onChange={(value) => onChange({ to: value })}
            value={toValue}
            options={filteredToOptions}
            isSearchable={true}
            isFreePlan={isFreePlan}
          />
        </Box>

        <Box
          flex={1}
          bg={"#FFFFFF"}
          borderRadius={"12px"}
          maxW={{ base: "100%", lg: 218 }}
        >
          <Select
            placeholder="Program"
            labelLeftText={"Program:"}
            onChange={(value) => onChange({ program: value })}
            value={PROGRAMS_OPTIONS.find(
              (option) => option.value === program?.value
            )}
            options={PROGRAMS_OPTIONS}
          />
        </Box>

        <Box
          flex={1}
          bg={"#FFFFFF"}
          borderRadius={"12px"}
          maxW={{ base: "100%", lg: 218 }}
        >
          <Select
            placeholder="Month"
            labelLeftText={"Month:"}
            onChange={(value) => onChange({ date: value })}
            value={DATES_OPTIONS.find((option) => option.value === date.value)}
            options={getMonthOptions()}
            isAustralianPage={true}
            isFreePlan={isFreePlan}
          />
        </Box>
      </Flex>

      <Flex whiteSpace="nowrap">* indicates mixed cabin</Flex>
    </Flex>
  )
}

export default FilterPanel
