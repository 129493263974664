import { useState } from "react"
import { format, addDays } from "date-fns"
import { useTranslation } from "react-i18next"
import { useForm, Controller } from "react-hook-form"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import {
  Heading,
  Button,
  Text,
  useToast,
  Stack,
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  Checkbox,
  Image,
  Divider,
  Switch,
} from "@chakra-ui/react"
// import { ArrowForwardIcon } from "@chakra-ui/icons"
import { pickBy } from "lodash"
import invert from "lodash/invert"

import AlertDatePicker from "./AlertDatePicker"
import { COLORS } from "../constants/constants"
import { createAlert, updateAlert } from "../services/api"

import bellImage from "../img/bell.svg"
import airplaneImage from "../img/alerts_map_airplane.svg"

const flightClassesMapping = {
  Economy: "economy",
  PremiumEconomy: "premiumEconomy",
  Business: "business",
  First: "first",
}

const labelFlightClassMapping = invert(flightClassesMapping)

const AlertRouteContent = ({ route, onClose }) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const toast = useToast()

  const [fromDate, setFromDate] = useState(new Date(route.startDate))
  const [toDate, setToDate] = useState(addDays(new Date(route.endDate), 1))

  const preferredPrograms = [
    route.preferredPrograms.includes("VA") ? "VA" : "",
    route.preferredPrograms.includes("QF") ? "QF" : "",
    route.preferredPrograms.includes("AA") ? "AA" : "",
  ].filter((program) => program !== "")

  const { handleSubmit, control, errors, setValue } = useForm({
    values: {
      origin: route.origin,
      originRegion: route.originRegion?.enabled || false,
      destinationRegion: route.destinationRegion?.enabled || false,
      economy: route.flightClasses.some(
        (flightClass) => flightClass === "Economy"
      ),
      premiumEconomy: route.flightClasses.some(
        (flightClass) => flightClass === "PremiumEconomy"
      ),
      business: route.flightClasses.some(
        (flightClass) => flightClass === "Business"
      ),
      first: route.flightClasses.some((flightClass) => flightClass === "First"),
      VA: preferredPrograms.includes("VA"),
      QF: preferredPrograms.includes("QF"),
      AA: preferredPrograms.includes("AA"),
      isDirect: route.isDirect || false,
      status: route.status || "Pending",
    },
  })

  const { mutateAsync: createAlertMutation } = useMutation({
    mutationFn: createAlert,
    onSuccess: () => queryClient.invalidateQueries(["alerts"]),
  })

  const { mutateAsync: updateAlertMutation } = useMutation({
    mutationFn: updateAlert,
    onSuccess: () => queryClient.invalidateQueries(["alerts"]),
  })

  const onSubmit = async (data) => {
    const preferredPrograms = ["VA", "QF", "AA"].filter(
      (program) => data[program]
    )

    const formattedData = pickBy({
      start_date: format(fromDate, "yyyy-MM-dd"),
      end_date: format(toDate, "yyyy-MM-dd"),
      origin: route.origin.id,
      destination: route.destination.id,
      flight_classes: Object.keys(labelFlightClassMapping)
        .filter((key) => data[key])
        .map((key) => labelFlightClassMapping[key]),
      preferred_programs: preferredPrograms,
      direct: data.isDirect,
      status: route.isNew ? "Pending" : data.status,
      include_region: data.originRegion || data.destinationRegion,
      side: data.originRegion
        ? "origin"
        : data.destinationRegion
        ? "destination"
        : null,
    })

    try {
      if (route.id) {
        await updateAlertMutation({ id: route.id, ...formattedData })

        toast({
          position: "bottom-right",
          title: t("alertRouteModal.toastSuccessUpdated"),
          status: "success",
        })
      } else {
        await createAlertMutation(formattedData)

        toast({
          position: "bottom-right",
          title: t("alertRouteModal.toastSuccessCreated"),
          status: "success",
        })
      }

      await queryClient.invalidateQueries(["me"])
      onClose()
    } catch (err) {
      toast({
        position: "bottom-right",
        title: t(
          route.isNew
            ? err?.response?.data?.detail || "alertRouteModal.createToastError"
            : err?.response?.data?.detail || "alertRouteModal.editToastError"
        ),
        status: "error",
      })
    }
  }

  return (
    <Flex flexDirection={"column"} textAlign={"left"}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex flexDirection={"column"} backgroundColor={COLORS.grey} p={6}>
          <Flex mb="4" alignItems={"center"}>
            <Image
              src={bellImage}
              mr={3}
              alt={`create_reward_flight_alerts_from_${route?.origin.name}_to_${route?.destination.name}`}
            />
            <Heading
              as="h1"
              align="left"
              color={COLORS.black}
              fontSize={"xl"}
              fontWeight="extrabold"
            >
              {t("alertRouteModal.header")}
            </Heading>
          </Flex>

          <Flex flexDirection={"column"}>
            <Flex gap={3} alignItems={"center"}>
              <div
                style={{
                  borderRadius: "50%",
                  border: "2px solid #6A6E85",
                  width: "16px",
                  height: "16px",
                }}
              />
              <Flex flexDirection={"column"}>
                <Text fontSize="md" fontWeight={700}>
                  {route?.origin.name + " (" + route?.origin.code + ")"}
                </Text>

                {route.showRegionCheckboxes && (
                  <FormControl isInvalid={!!errors?.originRegion}>
                    <Controller
                      control={control}
                      name="originRegion"
                      render={({ field: { onChange, value, ref } }) => {
                        return (
                          <Checkbox
                            onChange={(e) => {
                              onChange(e)
                              if (e.target.checked) {
                                setValue("destinationRegion", false)
                              }
                            }}
                            ref={ref}
                            isChecked={value}
                            colorScheme={"red"}
                          >
                            <Text fontSize="xs" fontWeight="semibold">
                              Include all major airports from{" "}
                              {route.originRegion.name}
                            </Text>
                          </Checkbox>
                        )
                      }}
                    />
                    <FormErrorMessage>
                      {errors?.originRegion?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Flex>
            </Flex>

            <Flex>
              <Image src={airplaneImage} width="16px" height="36px" mr="2px" />
            </Flex>

            <Flex gap={3} alignItems={"center"}>
              <div
                style={{
                  borderRadius: "50%",
                  border: "2px solid #6A6E85",
                  width: "16px",
                  height: "16px",
                }}
              />

              <Flex flexDirection={"column"}>
                <Text fontSize="md" fontWeight={700}>
                  {route?.destination.name +
                    " (" +
                    route?.destination.code +
                    ")"}
                </Text>

                {route.showRegionCheckboxes && (
                  <FormControl isInvalid={!!errors?.destinationRegion}>
                    <Controller
                      control={control}
                      name="destinationRegion"
                      render={({ field: { onChange, value, ref } }) => {
                        return (
                          <Checkbox
                            onChange={(e) => {
                              onChange(e)
                              if (e.target.checked) {
                                setValue("originRegion", false)
                              }
                            }}
                            ref={ref}
                            isChecked={value}
                            colorScheme={"red"}
                          >
                            <Text fontSize="xs" fontWeight="semibold">
                              Include all major airports into{" "}
                              {route.destinationRegion.name}
                            </Text>
                          </Checkbox>
                        )
                      }}
                    />
                    <FormErrorMessage>
                      {errors?.destinationRegion?.message}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Flex>
            </Flex>
          </Flex>
        </Flex>

        <Flex flexDirection={"column"} p={6}>
          <Stack gap={3} direction="row">
            <Box className={"alerts-date-picker-wrapper"}>
              <Text mb={1} fontSize={"xs"}>
                From:
              </Text>

              <AlertDatePicker
                date={fromDate}
                toDate={toDate}
                startDate={fromDate}
                minDate={new Date()}
                handleDatePickerChange={(date) => {
                  setFromDate(date)
                  if (new Date(toDate) < date) {
                    setToDate(date)
                  }
                }}
                selectsStart={true}
              />
            </Box>

            <Box className={"alerts-date-picker-wrapper"}>
              <Text mb={1} fontSize={"xs"}>
                To:
              </Text>

              <AlertDatePicker
                date={toDate}
                toDate={toDate}
                startDate={fromDate}
                minDate={fromDate}
                handleDatePickerChange={(date) => {
                  setToDate(date)
                }}
                selectsEnd={true}
              />
            </Box>
          </Stack>

          <Divider color="rgba(33, 51, 63, 0.15)" my={3} />

          <Stack gap={3} direction="row">
            <Flex flexDirection={"column"} w="50%" gap={3}>
              <FormControl isInvalid={!!errors?.economy}>
                <Controller
                  control={control}
                  name="economy"
                  render={({ field: { onChange, value, ref } }) => {
                    return (
                      <Checkbox
                        onChange={onChange}
                        ref={ref}
                        isChecked={value}
                        colorScheme={"red"}
                      >
                        <Text fontSize="sm" fontWeight="semibold">
                          Economy
                        </Text>
                      </Checkbox>
                    )
                  }}
                />
                <FormErrorMessage>{errors?.economy?.message}</FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors?.business}>
                <Controller
                  control={control}
                  name="business"
                  render={({ field: { onChange, value, ref } }) => {
                    return (
                      <Checkbox
                        onChange={onChange}
                        ref={ref}
                        isChecked={value}
                        colorScheme={"red"}
                      >
                        <Text fontSize="sm" fontWeight="semibold">
                          Business
                        </Text>
                      </Checkbox>
                    )
                  }}
                />
                <FormErrorMessage>{errors?.business?.message}</FormErrorMessage>
              </FormControl>
            </Flex>

            <Flex flexDirection={"column"} w={"50%"} gap={3}>
              <FormControl isInvalid={!!errors?.premiumEconomy}>
                <Controller
                  control={control}
                  name="premiumEconomy"
                  render={({ field: { onChange, value, ref } }) => {
                    return (
                      <Checkbox
                        onChange={onChange}
                        ref={ref}
                        isChecked={value}
                        colorScheme={"red"}
                      >
                        <Text fontSize="sm" fontWeight="semibold">
                          Premium Economy
                        </Text>
                      </Checkbox>
                    )
                  }}
                />
                <FormErrorMessage>
                  {errors?.premiumEconomy?.message}
                </FormErrorMessage>
              </FormControl>

              <FormControl isInvalid={!!errors?.first}>
                <Controller
                  control={control}
                  name="first"
                  render={({ field: { onChange, value, ref } }) => {
                    return (
                      <Checkbox
                        onChange={onChange}
                        ref={ref}
                        isChecked={value}
                        colorScheme={"red"}
                      >
                        <Text fontSize="sm" fontWeight="semibold">
                          First Class
                        </Text>
                      </Checkbox>
                    )
                  }}
                />
                <FormErrorMessage>{errors?.first?.message}</FormErrorMessage>
              </FormControl>
            </Flex>
          </Stack>

          <Divider color="rgba(33, 51, 63, 0.15)" my={4} />

          <Box>
            <Text fontSize={"xs"}>Preferred Program</Text>

            <Stack gap={3} direction="row" mt={3} mb={4}>
              <Flex flexDirection={"column"} w="50%" gap={3}>
                <FormControl isInvalid={!!errors?.VA}>
                  <Controller
                    control={control}
                    name="VA"
                    render={({ field: { onChange, value, ref } }) => {
                      return (
                        <Checkbox
                          onChange={onChange}
                          ref={ref}
                          isChecked={value}
                          colorScheme={"red"}
                        >
                          <Text fontSize="sm" fontWeight="semibold">
                            Virgin Velocity
                          </Text>
                        </Checkbox>
                      )
                    }}
                  />
                  <FormErrorMessage>{errors?.VA?.message}</FormErrorMessage>
                </FormControl>

                <FormControl isInvalid={!!errors?.QF}>
                  <Controller
                    control={control}
                    name="QF"
                    render={({ field: { onChange, value, ref } }) => {
                      return (
                        <Checkbox
                          onChange={onChange}
                          ref={ref}
                          isChecked={value}
                          colorScheme={"red"}
                        >
                          <Text fontSize="sm" fontWeight="semibold">
                            Qantas FF
                          </Text>
                        </Checkbox>
                      )
                    }}
                  />
                  <FormErrorMessage>{errors?.QF?.message}</FormErrorMessage>
                </FormControl>
              </Flex>

              {/* <Flex flexDirection={"column"} w="50%" gap={3}>
                <FormControl isInvalid={!!errors?.AA}>
                  <Controller
                    control={control}
                    name="AA"
                    render={({ field: { onChange, value, ref } }) => {
                      return (
                        <Checkbox
                          onChange={onChange}
                          ref={ref}
                          isChecked={value}
                          colorScheme={"red"}
                        >
                          <Text fontSize="sm" fontWeight="semibold">
                            American Airlines
                          </Text>
                        </Checkbox>
                      )
                    }}
                  />
                  <FormErrorMessage>{errors?.AA?.message}</FormErrorMessage>
                </FormControl>
              </Flex> */}
            </Stack>
          </Box>

          <Flex backgroundColor={COLORS.grey} px={4} py={2}>
            <FormControl isInvalid={!!errors?.isDirect}>
              <Flex justifyContent={"space-between"} alignItems={"center"}>
                <Box>
                  <Text fontWeight={700}>Direct flights only</Text>
                  <Text fontWeight={600} fontSize={"12px"}>
                    on supported routes
                  </Text>
                </Box>
                <Controller
                  control={control}
                  name="isDirect"
                  render={({ field: { onChange, value, ref } }) => {
                    return (
                      <Switch
                        onChange={onChange}
                        ref={ref}
                        isChecked={value}
                        colorScheme={"red"}
                      />
                    )
                  }}
                />
              </Flex>
              <FormErrorMessage>{errors?.isDirect?.message}</FormErrorMessage>
            </FormControl>
          </Flex>

          <Text fontSize={"xs"} my={5}>
            {t("alertRouteModal.tip")}
          </Text>

          <Button
            type="submit"
            w={"100%"}
            backgroundColor={COLORS.red}
            color={COLORS.white}
            borderRadius={8}
            boxShadow={"0px 4px 12px rgba(0, 0, 0, 0.24)"}
            textTransform={"uppercase"}
          >
            {t(
              route.isNew
                ? "alertRouteModal.createButton"
                : "alertRouteModal.editButton"
            )}
          </Button>
        </Flex>
      </form>
    </Flex>
  )
}

export default AlertRouteContent
