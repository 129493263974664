import { useTranslation } from "react-i18next"
import { Box, Button, Flex, Image, Text } from "@chakra-ui/react"

import { handleTrackAirlineButton } from "../services/analytics"
import { COLORS } from "../constants/constants"
import velocity from "../img/velocity.png"

const numberFormat = new Intl.NumberFormat()

const formatDateForURL = (isoDateString) => {
  const [year, month, day] = isoDateString.split("T")[0].split("-")

  return `${month}-${day}-${year}`
}

const generateDynamicLink = (baseUrl, queryParams) => {
  const [baseWithoutHash, hash] = baseUrl.split("#")
  const queryString = Object.keys(queryParams)
    .map(
      (key) =>
        `${encodeURIComponent(key)}=${encodeURIComponent(queryParams[key])}`
    )
    .join("&")

  return `${baseWithoutHash}#${hash}?${queryString}`
}

const VelocityBookContent = ({ flight, highestPoint }) => {
  const { t } = useTranslation()

  const howToBookVelocityLink = t("links.howToBookVelocityLink")

  const params = {
    journeyType: "one-way",
    locale: "en-GB",
    awardBooking: true,
    origin: flight.origin.code,
    destination: flight.destination.code,
    ADT: 1,
    CHD: 0,
    INF: 0,
    date: formatDateForURL(flight.flight_start_date),
  }

  const dynamicVelocityLink = generateDynamicLink(howToBookVelocityLink, params)

  const handleButtonClick = () => {
    handleTrackAirlineButton({ name: "Velocity Airlines" })
  }

  return (
    <Flex flexDirection={"column"} gap={5}>
      <Flex justify={"space-between"} gap={"42px"} alignItems={"center"}>
        <Box>
          <Image
            src={velocity}
            height={"42px"}
            alt={"velocity_frequent_flyer_logo"}
          />
        </Box>
        <Box>
          <Text fontSize={"md"} fontWeight={"bold"} whiteSpace={"nowrap"}>
            {numberFormat.format(highestPoint.points)} {t("table.bookPoints")}
          </Text>
          <Text align={"right"} fontWeight={"bold"}>
            {t("table.bookTaxes")}
          </Text>
        </Box>
      </Flex>
      <Button
        as="a"
        href={dynamicVelocityLink}
        target="_blank"
        w={"100%"}
        height={"36px"}
        backgroundColor={COLORS.red}
        color="white"
        borderRadius={8}
        boxShadow={"0px 4px 12px rgba(0, 0, 0, 0.24)"}
        onClick={handleButtonClick}
      >
        {t("table.bookButton")}
      </Button>
    </Flex>
  )
}

export default VelocityBookContent
