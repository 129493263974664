import React from "react"
import { useParams } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import {
  Box,
  Flex,
  Heading,
  Image,
  ListItem,
  OrderedList,
  Text,
} from "@chakra-ui/react"
import { getPost } from "../services/api"
import Loading from "../components/Loading"
import NotFound from "./NotFound"
import { COLORS } from "../constants/constants"
import Footer from "../components/Footer"
import { postModulePageSeoTags } from "../helpers/seoTags"

const PostModule = () => {
  const params = useParams()

  const { data, isLoading } = useQuery({
    queryKey: ["post", params?.postUrl],
    queryFn: getPost,
  })

  if (isLoading) {
    return (
      <Box w={"100%"} pos="relative" flex={"1 1 auto"}>
        <Loading />
      </Box>
    )
  }

  if (!isLoading && data === undefined) {
    return <NotFound />
  }

  const {
    title,
    sub_heading,
    post_image,
    post_image_alt,
    summary,
    sections,
    meta_description,
    meta_keywords,
  } = data

  return (
    <>
      {postModulePageSeoTags({
        title,
        meta_description,
        meta_keywords,
        params,
      })}

      <Flex
        bg={COLORS.grey}
        flexGrow={1}
        minHeight={{ base: "calc(100vh - 48px)", lg: "calc(100vh - 60px)" }}
        flexDirection={"column"}
        justifyContent={"space-between"}
        marginInlineStart={0}
      >
        <Box marginBottom={"30px"} px={{ base: 4, lg: 0 }}>
          <Box mb={"30px"}>
            <Heading
              as="h1"
              align="left"
              pb="3"
              color={COLORS.black}
              fontSize={{ base: "2xl", lg: "4xl" }}
              fontWeight={"bold"}
            >
              {title}
            </Heading>

            <Text
              align="left"
              color={COLORS.black}
              fontSize={{ base: "lg", lg: "2xl" }}
              maxW={{ base: "100%", lg: "90%" }}
              fontWeight={"bold"}
            >
              {sub_heading}
            </Text>
          </Box>

          <Flex gap={["16px", "30px"]}>
            <Flex flex={"1 1 75%"} direction={"column"} gap={[3, "30px"]}>
              <Image
                src={post_image}
                w={"100%"}
                alt={post_image_alt}
                borderRadius={"12px"}
              />

              {summary && (
                <Box
                  p={[4, "30px"]}
                  backgroundColor={"#fff"}
                  borderRadius={"12px"}
                  border={"1px solid #EBEBEB"}
                  textAlign={"left"}
                >
                  <Text
                    fontSize={"20px"}
                    fontWeight={"bold"}
                    lineHeight={"26px"}
                    mb={"20px"}
                  >
                    {summary.heading}
                  </Text>

                  <Box mb={"24px"} fontSize={"14px"}>
                    <OrderedList
                      sx={{
                        display: "grid",
                        gridTemplateRows: "repeat(3, auto)",
                        gridAutoFlow: "column",
                        gap: "16px 30px",
                        counterReset: "list-counter",
                        marginLeft: 0,
                        paddingLeft: 0,
                        "> li": {
                          listStyle: "none",
                          position: "relative",
                          paddingLeft: "24px",
                          "&::before": {
                            content: 'counter(list-counter) "."',
                            counterIncrement: "list-counter",
                            position: "absolute",
                            left: 0,
                            color: COLORS.red,
                          },
                        },
                      }}
                    >
                      {summary.bullet_points.map((item, index) => (
                        <ListItem key={index}>
                          <Text as={"h2"}>{item}</Text>
                        </ListItem>
                      ))}
                    </OrderedList>
                  </Box>

                  <Text fontSize={"14px"}>{summary.description}</Text>
                </Box>
              )}

              {sections.map((section, index) => (
                <Flex key={index} direction={"column"} gap={[3, "30px"]}>
                  <Box>
                    <Heading
                      as="h3"
                      align="left"
                      mb="2"
                      color={COLORS.black}
                      fontSize={{ base: "lg", lg: "2xl" }}
                      fontWeight={"bold"}
                    >
                      {section.title}
                    </Heading>
                    <Text
                      align="left"
                      color={COLORS.black}
                      fontSize={{ base: "xs", lg: "sm" }}
                    >
                      {section.description}
                    </Text>
                  </Box>

                  {section.image && (
                    <Image
                      src={section.image}
                      w={"100%"}
                      alt={section.image_alt_text}
                    />
                  )}
                </Flex>
              ))}
            </Flex>
          </Flex>
        </Box>

        <Footer />
      </Flex>
    </>
  )
}

export default PostModule
