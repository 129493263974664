import React from "react"
import AlertsWithMap from "../components/AlertsWithMap"
import { alertsWithMapPageSeoTags } from "../helpers/seoTags"

const AlertsWithMapPage = () => {
  return (
    <>
      {alertsWithMapPageSeoTags()}

      <AlertsWithMap />
    </>
  )
}

export default AlertsWithMapPage
