import { useState } from "react"
import {
  Box,
  Heading,
  Table,
  Thead,
  Tbody,
  Th,
  Tr,
  Td,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverCloseButton,
  PopoverBody,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Flex,
  Text,
  Show,
  useToast,
  useBreakpointValue,
  Image,
} from "@chakra-ui/react"
import { DeleteIcon, EditIcon, RepeatIcon } from "@chakra-ui/icons"
import { useTranslation } from "react-i18next"
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query"
import parse from "date-fns/parse"
import format from "date-fns/format"

import {
  getAlerts,
  deleteAlert,
  updateAlert,
  getPmaxAlerts,
  updatePmaxAlert,
  deletePmaxAlert,
} from "../../services/api"
import AlertRouteContent from "../AlertRouteContent"
import { trackPage } from "../../services/analytics"
import { COLORS, SUBSCRIPTION } from "../../constants/constants"
import NoAlertsGuidance from "./components/NoAlertsGuidance"
import AlertComponent from "../AlertsWithMap/components/AlertComponent"
import { useAuthContext } from "../../services/auth"
import { alertsPageSeoTags } from "../../helpers/seoTags"
import LoadingAnimation from "../LoadingAnimation"
import Footer from "../Footer"

import hourlyAlertsIcon from "../../img/economy_icon.png"

const DATE_FORMAT = "MMMM dd, yyyy"
const DEFAULT_DATE_FORMAT = "yyyy-MM-dd"

const parseDate = (dateStr) => {
  const parsedDate = parse(dateStr, DEFAULT_DATE_FORMAT, new Date())
  return format(parsedDate, DATE_FORMAT)
}

const flightClassesMapping = {
  Economy: "economy",
  PremiumEconomy: "premiumEconomy",
  Business: "business",
  First: "first",
}

const Alerts = () => {
  const toast = useToast()
  const { t } = useTranslation()
  const { user } = useAuthContext()
  const queryClient = useQueryClient()

  const [openAlerts, setOpenAlerts] = useState({})
  const placement = useBreakpointValue({ base: "bottom", sm: "left" })

  const isMonthlyMaxAnnual =
    !user ||
    user.subscription === SUBSCRIPTION.FREE ||
    user.subscription === SUBSCRIPTION.MONTHLY_LITE

  const { data: alerts, isFetching: isFetchingAlerts } = useQuery({
    queryKey: ["alerts"],
    queryFn: getAlerts,
    initialData: [],
  })

  const { data: pmaxAlerts, isFetching: isFetchingPmaxAlerts } = useQuery({
    queryKey: ["pmaxAlerts"],
    queryFn: getPmaxAlerts,
    initialData: [],
  })

  const isAlertsLoading = isFetchingAlerts || isFetchingPmaxAlerts
  const allAlerts = [...pmaxAlerts, ...alerts]

  const { mutateAsync: updateAlertMutation } = useMutation({
    mutationFn: updateAlert,
    onSuccess: () => queryClient.invalidateQueries(["alerts"]),
  })

  const { mutateAsync: deleteAlertMutation } = useMutation({
    mutationFn: deleteAlert,
    onSuccess: () => {
      queryClient.invalidateQueries(["alerts"])
    },
  })

  const { mutateAsync: updatePmaxAlertMutation } = useMutation({
    mutationFn: updatePmaxAlert,
    onSuccess: () => queryClient.invalidateQueries(["pmaxAlerts"]),
  })

  const { mutateAsync: deletePmaxAlertMutation } = useMutation({
    mutationFn: deletePmaxAlert,
    onSuccess: () => {
      queryClient.invalidateQueries(["pmaxAlerts"])
    },
  })

  const onAlertDelete = async (userAlert) => {
    userAlert.pmax_alert
      ? await deletePmaxAlertMutation(userAlert.id)
      : await deleteAlertMutation(userAlert.id)

    await queryClient.invalidateQueries(["me"])
    onClose()
  }

  const onOpen = (alertId) => {
    setOpenAlerts((prev) => ({ ...prev, [alertId]: true }))
  }

  const onClose = (alertId) => {
    setOpenAlerts((prev) => ({ ...prev, [alertId]: false }))
  }

  const handleRepeatAlert = async (route) => {
    const formattedData = {
      start_date: route.startDate,
      end_date: route.endDate,
      origin: route.origin.id,
      destination: route.destination.id,
      flight_classes: route.flightClasses,
      preferred_programs: route.preferredPrograms,
      direct: route.isDirect,
      status: "Pending",
    }

    try {
      route.pmax_alert
        ? await updatePmaxAlertMutation({ id: route.id, ...formattedData })
        : await updateAlertMutation({ id: route.id, ...formattedData })

      toast({
        position: "bottom-right",
        title: t("alertRouteModal.toastSuccessUpdated"),
        status: "success",
      })
    } catch (err) {
      toast({
        position: "bottom-right",
        title: t("alertRouteModal.editToastError"),
        status: "error",
      })
    }
  }

  // if (true) {
  //   return <LoadingAnimation />
  // }

  return (
    <>
      {alertsPageSeoTags()}

      <Flex
        bg={COLORS.grey}
        flexGrow={1}
        minHeight={{ base: "calc(100vh - 48px)", lg: "calc(100vh - 60px)" }}
        flexDirection={"column"}
        justifyContent={"space-between"}
      >
        <Box
          bg={COLORS.grey}
          px={{ base: 4, lg: 0 }}
          marginInlineStart={0}
          flexGrow={1}
        >
          <Flex gap={6} alignItems={"center"} mb={6}>
            <Heading
              as="h1"
              color={COLORS.black}
              textAlign="left"
              fontSize={{ base: "xl", lg: "2xl" }}
            >
              {t("alerts.header")}
            </Heading>

            <Flex gap={2} alignItems={"center"}>
              <Image
                src={hourlyAlertsIcon}
                width={4}
                height={4}
                alt={"Hourly alerts icon"}
              />

              <Text color={COLORS.black}>denotes hourly search</Text>
            </Flex>
          </Flex>
          {isAlertsLoading ? (
            <LoadingAnimation />
          ) : (
            <Box bg="white" borderRadius={12} mb={7}>
              {allAlerts.length > 0 ? (
                <Table
                  width={"100%"}
                  sx={{ tableLayout: "fixed" }}
                  textAlign={"left"}
                >
                  <Thead>
                    <Tr
                      boxShadow="0px 2px 8px rgba(20, 23, 37, 0.08)"
                      borderTopRadius="12px"
                      sx={{
                        "& > th": {
                          fontSize: { base: "2xs", sm: "sm" },
                        },
                      }}
                    >
                      <Th
                        textTransform="none"
                        p={4}
                        w={{ base: "18%", sm: "20%" }}
                      >
                        {t("alerts.itinerary")}
                      </Th>
                      <Th
                        textTransform="none"
                        p={4}
                        w={{ base: "22%", sm: "20%" }}
                      >
                        {t("alerts.dateRange")}
                      </Th>
                      <Th
                        textTransform="none"
                        p={4}
                        w={{ base: "15%", sm: "18%" }}
                      >
                        {t("alerts.class")}
                      </Th>
                      <Show above={"lg"}>
                        <Th textTransform="none" p={4} w={"18%"}>
                          {t("alerts.passengers")}
                        </Th>
                      </Show>
                      <Show above={"lg"}>
                        <Th textTransform="none" p={4} w={"18%"}>
                          {t("alerts.program")}
                        </Th>
                      </Show>
                      <Th textTransform="none" p={4} w={"14%"}>
                        {t("alerts.status")}
                      </Th>
                      <Th
                        textTransform="none"
                        p={2}
                        w={{ base: "12%", lg: "10%" }}
                      ></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {allAlerts.map((userAlert) => {
                      const flightClasses = userAlert.flight_classes.map(
                        (flightClass) =>
                          t(`table.${flightClassesMapping[flightClass]}`)
                      )

                      const route = {
                        id: userAlert.id,
                        origin: userAlert.origin,
                        destination: userAlert.destination,
                        startDate: userAlert.start_date,
                        endDate: userAlert.end_date,
                        pmax_alert: userAlert.pmax_alert,
                        flightClasses: userAlert.flight_classes,
                        preferredPrograms: userAlert.preferred_programs || [],
                        isDirect: userAlert.direct,
                        status: userAlert.status,
                        isNew: false,
                        pmaxValue: userAlert.pmax_value || 1,
                      }

                      return (
                        <Tr
                          key={userAlert.id}
                          sx={{
                            "& > td": {
                              fontSize: { base: "2xs", sm: "xs", md: "sm" },
                            },
                          }}
                        >
                          <Td p={4} textAlign={"left"}>
                            {userAlert.origin.name} -{" "}
                            {userAlert.destination.name}
                            {userAlert.pmax_alert && (
                              <Image
                                display={"inline-block"}
                                ml={1}
                                mb={"-2px"}
                                src={hourlyAlertsIcon}
                                width={4}
                                height={4}
                                alt={"Hourly alerts icon"}
                              />
                            )}
                          </Td>

                          <Td p={4}>
                            {parseDate(userAlert.start_date)} -{" "}
                            {parseDate(userAlert.end_date)}
                          </Td>

                          <Td p={4}>{flightClasses?.join(", ")}</Td>

                          <Show above={"lg"}>
                            <Td p={4}>{route.pmaxValue}</Td>
                          </Show>

                          <Show above={"lg"}>
                            <Td p={4}>
                              {userAlert.pmax_alert
                                ? "QF"
                                : userAlert.preferred_programs
                                    ?.join(", ")
                                    .toUpperCase()}
                            </Td>
                          </Show>

                          <Td p={4}>
                            <Flex alignItems={"center"} gap={2}>
                              <Text>
                                {userAlert.status === "Pending"
                                  ? "Active"
                                  : userAlert.status}
                              </Text>
                              {userAlert.status === "Sent" && (
                                <Button
                                  onClick={() => handleRepeatAlert(route)}
                                  padding={0}
                                  width={"30px"}
                                  height={"30px"}
                                  minWidth={"30px"}
                                >
                                  <RepeatIcon color={"red"} />
                                </Button>
                              )}
                            </Flex>
                          </Td>
                          <Td p={2} textAlign="center">
                            <Flex
                              flexDirection={{
                                base: "row",
                                lg: "column",
                                xl: "row",
                              }}
                              gap={{ base: 1, sm: 2, md: 4 }}
                            >
                              <Flex>
                                <DeleteIcon
                                  cursor="pointer"
                                  boxSize={{ base: 4, md: 5 }}
                                  color="#6a6e85"
                                  onClick={() => onOpen(userAlert.id)}
                                />
                                <Modal
                                  isOpen={openAlerts[userAlert.id]}
                                  onClose={() => onClose(userAlert.id)}
                                  isCentered
                                >
                                  <ModalOverlay />
                                  <ModalContent>
                                    <ModalHeader
                                      fontSize={"2xl"}
                                      fontWeight="bold"
                                    >
                                      {t("alerts.deleteHeader")}
                                    </ModalHeader>
                                    <ModalCloseButton />
                                    <ModalBody>
                                      {t("alerts.deleteContent")}
                                    </ModalBody>

                                    <ModalFooter>
                                      <Flex width="100%" gap={5}>
                                        <Button
                                          flex={1}
                                          textTransform={"uppercase"}
                                          backgroundColor={COLORS.grey}
                                          color={COLORS.red}
                                          onClick={() => {
                                            onClose(userAlert.id)
                                          }}
                                        >
                                          {t("alerts.deleteCancel")}
                                        </Button>
                                        <Button
                                          flex={1}
                                          textTransform={"uppercase"}
                                          backgroundColor={COLORS.red}
                                          color={COLORS.white}
                                          onClick={() =>
                                            onAlertDelete(userAlert)
                                          }
                                        >
                                          {t("alerts.deleteAgree")}
                                        </Button>
                                      </Flex>
                                    </ModalFooter>
                                  </ModalContent>
                                </Modal>
                              </Flex>

                              <Flex
                                sx={{
                                  "& > div": {
                                    minWidth: "unset!important",
                                  },
                                }}
                              >
                                <Popover
                                  placement={placement}
                                  closeOnBlur={true}
                                  onOpen={() => {
                                    trackPage({
                                      title: "Alert Route",
                                    })
                                  }}
                                >
                                  {({ onClose }) => (
                                    <>
                                      <PopoverTrigger>
                                        <EditIcon
                                          cursor="pointer"
                                          boxSize={{ base: 4, md: 5 }}
                                          color="#6a6e85"
                                        />
                                      </PopoverTrigger>
                                      <PopoverContent
                                        _focus={{ boxShadow: "none" }}
                                        boxShadow="0px 10px 22px rgba(0, 0, 0, 0.14);"
                                        borderRadius={8}
                                        w={{ base: "100%", sm: 380 }}
                                        onClick={(e) => e.stopPropagation()}
                                      >
                                        <PopoverCloseButton />
                                        <PopoverBody p={0}>
                                          {route.pmax_alert ? (
                                            <AlertComponent
                                              route={route}
                                              onClose={onClose}
                                              isMonthlyMaxAnnual={
                                                isMonthlyMaxAnnual
                                              }
                                            />
                                          ) : (
                                            <AlertRouteContent
                                              route={route}
                                              onClose={onClose}
                                            />
                                          )}
                                        </PopoverBody>
                                      </PopoverContent>
                                    </>
                                  )}
                                </Popover>
                              </Flex>
                            </Flex>
                          </Td>
                        </Tr>
                      )
                    })}
                  </Tbody>
                </Table>
              ) : (
                <NoAlertsGuidance />
              )}
            </Box>
          )}
        </Box>
        <Footer />
      </Flex>
    </>
  )
}

export default Alerts
