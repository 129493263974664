import { useState, useEffect } from "react"
import { useQuery } from "@tanstack/react-query"
import { useNavigate, useParams, useSearchParams } from "react-router-dom"
import { Box, Heading, Text, Flex } from "@chakra-ui/react"
import { Trans, useTranslation } from "react-i18next"
import { format } from "date-fns"

import Footer from "../components/Footer"
import FilterPanel from "../components/AmadeusFlights/FilterPanel"
import AmadeusFlightDetail from "../components/AmadeusFlights/AmadeusFlightDetail"
import { useAuthContext } from "../services/auth"
import { handleTrackSearch, trackPage } from "../services/analytics"
import { getAmadeusFlights, getAmadeusOriginAirports } from "../services/api"
import {
  generateRandomNumbers,
  getCodeFromRoute,
  handleFlightSelection,
} from "../helpers/functions"
import {
  COLORS,
  SUBSCRIPTION,
  defaultFlightsTexts,
  defaultTop10Airports,
  top100AirportCodes,
} from "../constants/constants"

import "../App.css"
import { flightAvailabilityPageSeoTags } from "../helpers/seoTags"
import StrategiesForUpdating from "../components/AmadeusFlights/components/StrategiesForUpdating"
import FAQsBetweenFlights from "../components/AmadeusFlights/components/FAQsBetweenFlights"
import PopularFlights from "../components/PopularFlights"
import AirlineCodesInfo from "../components/AmadeusFlights/components/AirlineCodesInfo"

const { FREE } = SUBSCRIPTION

const randomNumbers = generateRandomNumbers(100)

const Amadeus = () => {
  const { route } = useParams()
  const [searchParams, setSearchParams] = useSearchParams()
  const navigate = useNavigate()
  const { user } = useAuthContext()
  const { t } = useTranslation()
  const [top10Airports, setTop10Airports] = useState(null)
  const [flightsTexts, setFlightsTexts] = useState(null)
  const [filters, setFilters] = useState({
    from: null,
    to: null,
    date: null,
  })

  const originAirportsQuery = useQuery({
    queryKey: ["amadeusOriginAirports"],
    queryFn: getAmadeusOriginAirports,
    initialData: [],
  })

  const params = {
    page: searchParams.get("page") || 1,
    departure_date: filters.date ? format(filters.date, "yyyy-MM-dd") : null,
    origin: filters.from?.value,
    destination: filters.to?.value,
  }

  const { data, isFetching, isError, error, status } = useQuery({
    queryKey: ["amadeusFlights", params, user],
    queryFn: getAmadeusFlights,
    keepPreviousData: true,
    initialData: { count: 0, results: [] },
    enabled:
      !!(params.origin && params.destination && params.departure_date) ||
      !user ||
      user?.subscription.toLowerCase() === FREE,
    retry: (_, error) => {
      return (
        error.message !== "API request limit reached" ||
        error.message !== "No cached data available"
      )
    },
  })

  useEffect(() => {
    trackPage({ title: "Flights Availability" })
  }, [])

  useEffect(() => {
    if (filters.from && filters.to) {
      handleTrackSearch({
        origin: filters.from,
        destination: filters.to,
      })
    }
  }, [filters.from, filters.to])

  useEffect(() => {
    if (originAirportsQuery.data.length > 0 && route) {
      const codes = getCodeFromRoute(route)

      let from, to

      if (codes) {
        from = originAirportsQuery.data.find(
          (f) => f.code.toLowerCase() === codes.origin
        )
        to = originAirportsQuery.data.find(
          (t) => t.code.toLowerCase() === codes.destination
        )
      } else if (data.results.length > 0) {
        const length = data.results[0].details?.length
        const fromCode = data.results[0].details[0]?.from_airport
        const toCode = data.results[0].details[length - 1]?.to_airport

        from = originAirportsQuery.data.find(
          (f) => f.code.toLowerCase() === fromCode.toLowerCase()
        )
        to = originAirportsQuery.data.find(
          (t) => t.code.toLowerCase() === toCode.toLowerCase()
        )
      }

      if (from && to) {
        setFlightsTexts({
          from: {
            name: from.name.trim(),
            code: from.code,
            fullName: `${from.name.trim()} (${from.code})`,
          },
          to: {
            name: to.name.trim(),
            code: to.code,
            fullName: `${to.name.trim()} (${to.code})`,
          },
        })

        const top10 = randomNumbers.reduce((acc, number) => {
          if (acc.length >= 10) return acc

          const code = top100AirportCodes[number]
          const airport = originAirportsQuery.data.find(
            (airport) => airport.code === code
          )

          if (
            airport &&
            airport.code !== from.code &&
            airport.code !== to.code
          ) {
            acc.push({
              name: airport.name.trim(),
              code: airport.code,
            })
          }

          return acc
        }, [])

        setTop10Airports(top10)
      }
    }
  }, [data, originAirportsQuery.data, route])

  useEffect(() => {
    if (route) {
      const codes = getCodeFromRoute(route)

      if (codes) {
        const from = originAirportsQuery.data.find(
          (airport) => airport.code.toLowerCase() === codes.origin
        )

        const to = originAirportsQuery.data.find(
          (airport) => airport.code.toLowerCase() === codes.destination
        )

        if (from && to) {
          setFilters((prevFilters) => ({
            ...prevFilters,
            from: {
              value: from.code,
              label: `${from.city.trim()} (${from.code})`,
              fullLabel: `${from.name.trim()} (${from.code})`,
            },
            to: {
              value: to.code,
              label: `${to.city.trim()} (${to.code})`,
              fullLabel: `${from.name.trim()} (${from.code})`,
            },
          }))
        }
      }
    }
  }, [route, originAirportsQuery.data])

  const applyFilters = (newFilters) => {
    let updateFrom = newFilters.from || filters.from
    let updateTo = newFilters.to || filters.to
    let updateDate = newFilters.date || filters.date

    const canUserRequest =
      updateFrom &&
      updateTo &&
      updateDate &&
      user &&
      user.subscription.toLowerCase() !== FREE &&
      user.remaining_requests > 0

    if (
      newFilters.from !== filters.from ||
      newFilters.to !== filters.to ||
      newFilters.date !== filters.date
    ) {
      if (canUserRequest) {
        const currentParams = new URLSearchParams(searchParams)
        currentParams.delete("page")
        setSearchParams(currentParams)

        const { fromURL, toURL } = handleFlightSelection({
          from: { value: updateFrom.value, fullLabel: updateFrom.fullLabel },
          to: { value: updateTo.value, fullLabel: updateTo.fullLabel },
        })

        navigate(`/flight-availability/${fromURL}-to-${toURL}`)
      }
    }

    setFilters((existingFilters) => ({ ...existingFilters, ...newFilters }))
  }

  return (
    <>
      {flightAvailabilityPageSeoTags({ flightsTexts, route })}

      <Flex
        bg={COLORS.grey}
        flexGrow={1}
        minHeight={{ base: "calc(100vh - 48px)", lg: "calc(100vh - 60px)" }}
        flexDirection={"column"}
        justifyContent={"space-between"}
      >
        <Box marginBottom={"30px"}>
          <Box px={{ base: 4, lg: 0 }}>
            <Heading
              as="h1"
              align="left"
              pb="2"
              color={COLORS.black}
              fontSize={{ base: "xl", lg: "2xl" }}
            >
              {!flightsTexts
                ? t("amadeus.titleDefault")
                : t("amadeus.title", {
                    from: flightsTexts.from.name,
                    fromCode: flightsTexts.from.code,
                    to: flightsTexts.to.name,
                    toCode: flightsTexts.to.code,
                  })}
            </Heading>
            <Text
              align="left"
              color={COLORS.black}
              pb="5"
              fontSize={{ base: "small", lg: "sm" }}
            >
              {!flightsTexts ? (
                <>
                  <p>
                    <Trans
                      i18nKey={"amadeus.defaultDescription1"}
                      components={{ bold: <strong /> }}
                    />
                  </p>
                  <p>
                    <Trans
                      i18nKey={"amadeus.defaultDescription2"}
                      components={{ bold: <strong /> }}
                    />
                  </p>
                </>
              ) : (
                <>
                  <p>
                    <Trans
                      i18nKey={"amadeus.description1"}
                      values={{
                        from: flightsTexts.from.name,
                        fromCode: flightsTexts.from.code,
                        to: flightsTexts.to.name,
                        toCode: flightsTexts.to.code,
                      }}
                      components={{ bold: <strong /> }}
                    />
                  </p>
                  <p>
                    <Trans
                      i18nKey={"amadeus.description2"}
                      components={{ bold: <strong /> }}
                    />
                  </p>
                </>
              )}
            </Text>
          </Box>

          <Box
            bg={{ base: "initial", lg: "white" }}
            borderRadius={[0, 12]}
            mb={7}
            py={{ base: 0, lg: 4 }}
          >
            <Box px={4} maxW={"1120px"}>
              <FilterPanel
                onChange={applyFilters}
                {...filters}
                user={user}
                originAirportsQuery={originAirportsQuery}
                destinationAirportsQuery={{ data: [] }}
              />
            </Box>

            {!(
              user &&
              user.subscription.toLowerCase() !== FREE &&
              !(params.origin && params.destination && params.departure_date)
            ) &&
              originAirportsQuery.data.length > 0 && (
                <AmadeusFlightDetail
                  data={data}
                  status={status}
                  isFetching={isFetching}
                  isError={isError}
                  error={error}
                  originAirportsQuery={originAirportsQuery}
                />
              )}
          </Box>

          {flightsTexts && user && (
            <Flex flexDirection={"column"} gap={"30px"} px={{ base: 4, sm: 0 }}>
              <StrategiesForUpdating flightsTexts={flightsTexts} />
              <FAQsBetweenFlights flightsTexts={flightsTexts} />
              {/* <HotelIdeas flightsTexts={flightsTexts} /> */}
              <PopularFlights
                flightsTexts={flightsTexts}
                top10Airports={top10Airports}
                url={"flight-availability"}
              />
            </Flex>
          )}

          {(!flightsTexts || !user) && (
            <Flex flexDirection={"column"} gap={"30px"} px={{ base: 4, lg: 0 }}>
              <AirlineCodesInfo />
              <PopularFlights
                flightsTexts={flightsTexts || defaultFlightsTexts}
                top10Airports={top10Airports || defaultTop10Airports}
                url={"flight-availability"}
              />
            </Flex>
          )}
        </Box>

        <Footer />
      </Flex>
    </>
  )
}

export default Amadeus
